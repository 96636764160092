/* tslint:disable */
/* eslint-disable */
/* @relayHash c701daae54f6da664d05a18a95d8881e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_FLAG_REASON = "COMMENT_DETECTED_BANNED_WORD" | "COMMENT_DETECTED_LINKS" | "COMMENT_DETECTED_NEW_COMMENTER" | "COMMENT_DETECTED_PREMOD_USER" | "COMMENT_DETECTED_RECENT_HISTORY" | "COMMENT_DETECTED_REPEAT_POST" | "COMMENT_DETECTED_SPAM" | "COMMENT_DETECTED_SUSPECT_WORD" | "COMMENT_DETECTED_TOXIC" | "COMMENT_REPORTED_ABUSIVE" | "COMMENT_REPORTED_BIO" | "COMMENT_REPORTED_OFFENSIVE" | "COMMENT_REPORTED_OTHER" | "COMMENT_REPORTED_SPAM" | "%future added value";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type MODERATION_QUEUE = "PENDING" | "REPORTED" | "UNMODERATED" | "%future added value";
export type TAG = "ADMIN" | "EXPERT" | "FEATURED" | "MEMBER" | "MODERATOR" | "QUESTION" | "REVIEW" | "STAFF" | "UNANSWERED" | "%future added value";
export type SectionFilter = {
    name?: string | null;
};
export type QueueCommentLeftSubscriptionVariables = {
    storyID?: string | null;
    siteID?: string | null;
    section?: SectionFilter | null;
    orderBy?: COMMENT_SORT | null;
    queue: MODERATION_QUEUE;
};
export type QueueCommentLeftSubscriptionResponse = {
    readonly commentLeftModerationQueue: {
        readonly comment: {
            readonly id: string;
            readonly status: COMMENT_STATUS;
            readonly tags: ReadonlyArray<{
                readonly code: TAG;
            }>;
            readonly revision: {
                readonly actionCounts: {
                    readonly flag: {
                        readonly reasons: {
                            readonly COMMENT_REPORTED_SPAM: number;
                            readonly COMMENT_REPORTED_OTHER: number;
                            readonly COMMENT_REPORTED_OFFENSIVE: number;
                            readonly COMMENT_REPORTED_ABUSIVE: number;
                            readonly COMMENT_REPORTED_BIO: number;
                            readonly COMMENT_DETECTED_TOXIC: number;
                            readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                            readonly COMMENT_DETECTED_SPAM: number;
                            readonly COMMENT_DETECTED_REPEAT_POST: number;
                            readonly COMMENT_DETECTED_RECENT_HISTORY: number;
                            readonly COMMENT_DETECTED_NEW_COMMENTER: number;
                            readonly COMMENT_DETECTED_LINKS: number;
                            readonly COMMENT_DETECTED_BANNED_WORD: number;
                        };
                    };
                };
                readonly metadata: {
                    readonly wordList: {
                        readonly timedOut: boolean | null;
                    } | null;
                    readonly externalModeration: ReadonlyArray<{
                        readonly name: string;
                        readonly analyzedAt: string;
                        readonly result: {
                            readonly status: COMMENT_STATUS | null;
                            readonly tags: ReadonlyArray<TAG> | null;
                            readonly actions: ReadonlyArray<{
                                readonly reason: COMMENT_FLAG_REASON | null;
                            }> | null;
                        };
                    }> | null;
                };
            } | null;
            readonly viewerDidModerate: boolean | null;
            readonly statusHistory: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly status: COMMENT_STATUS;
                        readonly moderator: {
                            readonly id: string;
                            readonly username: string | null;
                        } | null;
                    };
                }>;
            };
            readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_comment">;
        };
    };
};
export type QueueCommentLeftSubscription = {
    readonly response: QueueCommentLeftSubscriptionResponse;
    readonly variables: QueueCommentLeftSubscriptionVariables;
};



/*
subscription QueueCommentLeftSubscription(
  $storyID: ID
  $siteID: ID
  $section: SectionFilter
  $orderBy: COMMENT_SORT
  $queue: MODERATION_QUEUE!
) {
  commentLeftModerationQueue(storyID: $storyID, siteID: $siteID, section: $section, orderBy: $orderBy, queue: $queue) {
    comment {
      id
      status
      ...ModerateCardDetailsContainer_comment
      tags {
        code
      }
      revision {
        actionCounts {
          flag {
            reasons {
              COMMENT_REPORTED_SPAM
              COMMENT_REPORTED_OTHER
              COMMENT_REPORTED_OFFENSIVE
              COMMENT_REPORTED_ABUSIVE
              COMMENT_REPORTED_BIO
              COMMENT_DETECTED_TOXIC
              COMMENT_DETECTED_SUSPECT_WORD
              COMMENT_DETECTED_SPAM
              COMMENT_DETECTED_REPEAT_POST
              COMMENT_DETECTED_RECENT_HISTORY
              COMMENT_DETECTED_NEW_COMMENTER
              COMMENT_DETECTED_LINKS
              COMMENT_DETECTED_BANNED_WORD
            }
          }
        }
        metadata {
          wordList {
            timedOut
          }
          externalModeration {
            name
            analyzedAt
            result {
              status
              tags
              actions {
                reason
              }
            }
          }
        }
        id
      }
      statusHistory(first: 1) {
        edges {
          node {
            status
            moderator {
              id
              username
            }
            id
          }
        }
      }
    }
  }
}

fragment AutomatedActionsContainer_comment on Comment {
  revision {
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        analyzedAt
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
      id
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  id
  status
  tags {
    code
  }
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SPAM
        }
      }
      reaction {
        total
      }
    }
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
  ...AutomatedActionsContainer_comment
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "orderBy"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "queue"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "section"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v5 = [
        ({
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
        } as any),
        ({
            "kind": "Variable",
            "name": "queue",
            "variableName": "queue"
        } as any),
        ({
            "kind": "Variable",
            "name": "section",
            "variableName": "section"
        } as any),
        ({
            "kind": "Variable",
            "name": "siteID",
            "variableName": "siteID"
        } as any),
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_SPAM",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_OTHER",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_OFFENSIVE",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_ABUSIVE",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_BIO",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_TOXIC",
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_SUSPECT_WORD",
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_SPAM",
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_REPEAT_POST",
        "storageKey": null
    } as any), v18 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_RECENT_HISTORY",
        "storageKey": null
    } as any), v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_NEW_COMMENTER",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_LINKS",
        "storageKey": null
    } as any), v21 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_BANNED_WORD",
        "storageKey": null
    } as any), v22 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevisionWordListMetadata",
        "kind": "LinkedField",
        "name": "wordList",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timedOut",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v23 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v24 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "analyzedAt",
        "storageKey": null
    } as any), v25 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
    } as any), v26 = ({
        "alias": null,
        "args": null,
        "concreteType": "ExternalModerationPhaseResult",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ExternalModerationPhaseAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                    (v25 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v27 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 1
        } as any)
    ], v28 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v29 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "moderator",
        "plural": false,
        "selections": [
            (v6 /*: any*/),
            (v28 /*: any*/)
        ],
        "storageKey": null
    } as any), v30 = ({
        "kind": "ClientExtension",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerDidModerate",
                "storageKey": null
            }
        ]
    } as any), v31 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v32 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v33 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v34 = [
        (v31 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "QueueCommentLeftSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "CommentLeftModerationQueuePayload",
                    "kind": "LinkedField",
                    "name": "commentLeftModerationQueue",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ActionCounts",
                                            "kind": "LinkedField",
                                            "name": "actionCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "flag",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FlagReasonActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "reasons",
                                                            "plural": false,
                                                            "selections": [
                                                                (v9 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v14 /*: any*/),
                                                                (v15 /*: any*/),
                                                                (v16 /*: any*/),
                                                                (v17 /*: any*/),
                                                                (v18 /*: any*/),
                                                                (v19 /*: any*/),
                                                                (v20 /*: any*/),
                                                                (v21 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevisionMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                (v22 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "externalModeration",
                                                    "plural": true,
                                                    "selections": [
                                                        (v23 /*: any*/),
                                                        (v24 /*: any*/),
                                                        (v26 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v27 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "kind": "LinkedField",
                                    "name": "statusHistory",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v29 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "statusHistory(first:1)"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardDetailsContainer_comment"
                                },
                                (v30 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v4 /*: any*/),
                (v3 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "QueueCommentLeftSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "CommentLeftModerationQueuePayload",
                    "kind": "LinkedField",
                    "name": "commentLeftModerationQueue",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EditInfo",
                                    "kind": "LinkedField",
                                    "name": "editing",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "edited",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ActionCounts",
                                            "kind": "LinkedField",
                                            "name": "actionCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "flag",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FlagReasonActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "reasons",
                                                            "plural": false,
                                                            "selections": [
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v9 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v14 /*: any*/),
                                                                (v16 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v15 /*: any*/),
                                                                (v17 /*: any*/),
                                                                (v18 /*: any*/),
                                                                (v19 /*: any*/),
                                                                (v20 /*: any*/),
                                                                (v21 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ReactionActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "reaction",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "total",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevisionMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionPerspectiveMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "perspective",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "score",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "externalModeration",
                                                    "plural": true,
                                                    "selections": [
                                                        (v23 /*: any*/),
                                                        (v26 /*: any*/),
                                                        (v24 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v22 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlagsConnection",
                                    "kind": "LinkedField",
                                    "name": "flags",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Flag",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "flagger",
                                                    "plural": false,
                                                    "selections": [
                                                        (v28 /*: any*/),
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v25 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "additionalDetails",
                                                    "storageKey": null
                                                },
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "site",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revisionHistory",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "body",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "media",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v31 /*: any*/),
                                                        (v32 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "width",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "height",
                                                            "storageKey": null
                                                        },
                                                        (v33 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "video",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "GiphyMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v34 /*: any*/),
                                                    "type": "TwitterMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v31 /*: any*/),
                                                        (v33 /*: any*/),
                                                        (v32 /*: any*/)
                                                    ],
                                                    "type": "YouTubeMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v34 /*: any*/),
                                                    "type": "ExternalMedia",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "story",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v31 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v27 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "kind": "LinkedField",
                                    "name": "statusHistory",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v29 /*: any*/),
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "statusHistory(first:1)"
                                },
                                (v30 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "c701daae54f6da664d05a18a95d8881e",
            "metadata": {},
            "name": "QueueCommentLeftSubscription",
            "operationKind": "subscription",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'cf9d594d783ca72b8f2b375decc9ac37';
export default node;
