/* tslint:disable */
/* eslint-disable */
/* @relayHash ea8e04b5eec4f4d096f0487830f96446 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoryInfoDrawerQueryVariables = {
    storyID: string;
};
export type StoryInfoDrawerQueryResponse = {
    readonly story: {
        readonly " $fragmentRefs": FragmentRefs<"StoryInfoDrawerContainer_story">;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"StoryInfoDrawerContainer_settings">;
    };
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"StoryInfoDrawerContainer_viewer">;
    } | null;
};
export type StoryInfoDrawerQuery = {
    readonly response: StoryInfoDrawerQueryResponse;
    readonly variables: StoryInfoDrawerQueryVariables;
};



/*
query StoryInfoDrawerQuery(
  $storyID: ID!
) {
  story(id: $storyID) {
    ...StoryInfoDrawerContainer_story
    id
  }
  settings {
    ...StoryInfoDrawerContainer_settings
    id
  }
  viewer {
    ...StoryInfoDrawerContainer_viewer
    id
  }
}

fragment ArchiveStoryActionsContainer_story on Story {
  id
  isArchiving
  isArchived
  isUnarchiving
  isClosed
  status
  settings {
    mode
  }
}

fragment ArchiveStoryActionsContainer_viewer on User {
  role
}

fragment ModerateStoryButton_settings on Settings {
  auth {
    integrations {
      sso {
        enabled
        targetFilter {
          admin
        }
      }
    }
  }
}

fragment ModerateStoryButton_story on Story {
  canModerate
  isArchiving
  isArchived
  id
}

fragment ModerateStoryButton_viewer on User {
  role
}

fragment StoryInfoDrawerContainer_settings on Settings {
  ...ModerateStoryButton_settings
}

fragment StoryInfoDrawerContainer_story on Story {
  id
  url
  status
  scrapedAt
  isArchived
  isArchiving
  metadata {
    title
    author
    publishedAt
  }
  ...ModerateStoryButton_story
  settings {
    ...StorySettingsContainer_storySettings
  }
  ...ArchiveStoryActionsContainer_story
}

fragment StoryInfoDrawerContainer_viewer on User {
  ...ArchiveStoryActionsContainer_viewer
  ...ModerateStoryButton_viewer
}

fragment StorySettingsContainer_storySettings on StorySettings {
  mode
  moderation
  premodLinksEnable
  experts {
    id
    username
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storyID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "storyID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "StoryInfoDrawerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "StoryInfoDrawerContainer_story"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "StoryInfoDrawerContainer_settings"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "StoryInfoDrawerContainer_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "StoryInfoDrawerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "scrapedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchiving",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoryMetadata",
                            "kind": "LinkedField",
                            "name": "metadata",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "author",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "publishedAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canModerate",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StorySettings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "mode",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "moderation",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "premodLinksEnable",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "experts",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isUnarchiving",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isClosed",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SSOAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "sso",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "enabled",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AuthenticationTargetFilter",
                                                    "kind": "LinkedField",
                                                    "name": "targetFilter",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "admin",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "ea8e04b5eec4f4d096f0487830f96446",
            "metadata": {},
            "name": "StoryInfoDrawerQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '53d400ddf844a4311d2540101f69fd43';
export default node;
